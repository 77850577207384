.ff-header {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 72px;
  /* identical to box height */

  letter-spacing: 0.2px;

  color: #ffffff;
}

.account-settings {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 0px 20px;
}

.account-settings__tab-list {
  background-color: black;
  padding: 0%;
  margin: 0%;
  border-radius: 20px 0px 0px 20px;
  width: max-content;
  padding-top: 48px;
  position: relative;
  min-width: 236px;
  display: flex;
  flex-direction: column;
}

.account-settings__tab-panel {
  background-color: #1f1f1f;
}

.account-settings__tab-panel--selected {
  padding: 48px;
  flex-grow: 1;
  border-radius: 0px 20px 20px 0px;
}

.account-settings__tab {
  list-style: none;
  height: 48px;
  line-height: 48px;
  white-space: nowrap;
  padding: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.account-settings__tab--selected {
  background-color: #1f1f1f;
}

.ff-button-logout {
  all: unset;
}

.bottom-center {
  position: absolute;
  bottom: 22px;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
}

.username-input {
  border: 1px solid #dadde4;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: transparent;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  /* identical to box height */

  color: #abb0ba;
}

.wallet-address {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  display: flex;
  align-items: center;

  color: #ffffff;
  text-overflow: ellipsis;
  width: 693px;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: middle;
  line-height: normal;
}

.wallet-address-line {
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  height: 48px;
  line-height: 48px;
  padding: 12px;
}

.wallet-delete-button {
  border-style: none;
  background-color: transparent;
  color: #ffffff;
  font-size: large;
  font-style: bold;
}

.wallet-icon {
  width: 36px;
  height: 36px;
  margin-right: 12px;
}

.transaction_tab {
  overflow: auto;
}

.transaction_table {
  border: none;
  display: flex;
  border-style: hidden !important;
}

.transaction_table_td {
  padding: 20px 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 45px;
  text-align: center;
  border-style: hidden !important;
  display: inline-flexbox;
  align-items: center;
  /* identical to box height, or 147% */

  color: #ffffff;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  overflow: auto;
}

.transaction_table_th {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-transform: uppercase;
  text-align: center;
  background-color: #1f1f1f;
  border-style: hidden !important;
  color: #8b909a;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.status_text_green {
  color: #3aff36;
}

.status_text_red {
  color: #ff6363;
}

.bound_text_blue {
  color: #3267e3;
}

.unbound_text_red {
  color: #e33232;
}
