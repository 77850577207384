@import url('https://fonts.googleapis.com/css2?family=Public+Sans&display=swap');

.admin-content {
  padding: 24px;
  display: flex;
  gap: 24px;
  flex-direction: column;
}

.panel {
  border: 5px solid white;
  padding: 24px;
  border-radius: 12px;
}
.admin-console {
  display: flex;
  width: 100%;
  height: 100%;

  color: white;
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}
.admin-console__tab-list {
  background-color: black;
  padding: 0%;
  margin: 0%;
  width: 236px;
  padding-top: 48px;
  position: relative;
  min-width: 236px;
}

.admin-console__tab-panel {
  background-color: #1f1f1f;
}

.admin-console__tab-panel--selected {
  padding: 48px;
  flex-grow: 1;
  overflow-x: scroll;
}

.admin-console__tab {
  list-style: none;
  height: 48px;
  line-height: 48px;
  white-space: nowrap;
  padding-left: 12px;
  cursor: pointer;
}

.admin-console__tab--selected {
  background-color: #1f1f1f;
}

.admin-table {
  width: 100%;
  color: black;
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 16px;
  background-color: white;
  margin-top: 16px;
  margin-bottom: 16px;
  text-align: center;
}

.admin-table-header {
  padding-right: 36px;
  padding-left: 36px;
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #dbdade;
  color: #8b909a;
}

.expanded-admin-table-row {
  background-color: #f2f4f6;
}

.admin-table-row {
  border-bottom: 1px solid #e9e7fd;
}

.admin-table-nested-row {
  display: flex;
  flex-direction: row;
}

.admin-table-cell {
  padding-right: 8px;
  padding-left: 8px;
  padding-bottom: 20px;
  padding-top: 20px;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* Apply a border to the bottom of all but the last row */
.admin-table > thead > tr:not(:last-child) > th,
.admin-table > thead > tr:not(:last-child) > td,
.admin-table > tbody > tr:not(:last-child) > th,
.admin-table > tbody > tr:not(:last-child) > td,
.admin-table > tfoot > tr:not(:last-child) > th,
.admin-table > tfoot > tr:not(:last-child) > td,
.admin-table > tr:not(:last-child) > td,
.admin-table > tr:not(:last-child) > th,
.admin-table > thead:not(:last-child),
.admin-table > tbody:not(:last-child),
.admin-table > tfoot:not(:last-child) {
  border-bottom: 1px solid #e9e7fd;
}
.admin-add-button {
  color: white;
  background-color: #3267e3;
  border: 1px solid #3267e3;
  border-radius: 6px;
  padding: 6px 16px 6px 16px;
}

.admin-stripe-add-button {
  color: white;
  background-color: #38be17;
  border: 1px solid #38be17;
  border-radius: 6px;
  padding: 6px 16px 6px 16px;
}

.admin-header-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.admin-button-cell {
  padding: 16px;
}

.draggable-icon-column {
  max-width: 36px;
}
.column-sort-icon {
  margin-left: 4px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.add-image-icon:hover {
  filter: brightness(0) saturate(100%) invert(31%) sepia(59%) saturate(2958%) hue-rotate(215deg)
    brightness(93%) contrast(90%);
}

.edit-action-button:hover {
  filter: brightness(0) saturate(100%) invert(25%) sepia(95%) saturate(1567%) hue-rotate(210deg)
    brightness(106%) contrast(87%);
}

.trash-action-button:hover {
  filter: brightness(0) saturate(100%) invert(30%) sepia(95%) saturate(2170%) hue-rotate(343deg)
    brightness(92%) contrast(92%);
}

.confirm-action-button:hover {
  filter: invert(68%) sepia(57%) saturate(4244%) hue-rotate(100deg) brightness(96%) contrast(83%);
}

.delete-action-button:hover {
  filter: brightness(0) saturate(100%) invert(20%) sepia(60%) saturate(5795%) hue-rotate(351deg)
    brightness(101%) contrast(78%);
}

.row-action-button {
  background-color: transparent;
  border: none;
  padding: 0px 6px;

  max-width: 100px;
  max-height: 100px;
  width: 3vw;
  height: auto;
  border-radius: 4px;
}

.row-action-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.column-header-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.collections-table {
  margin-top: 64px;
  border: 2px solid #dbdade;
  padding: 12px;
}

.collection-name-cell {
  text-transform: capitalize;
  font-weight: 600;
}

.table-subcomponent-wrapper {
  padding: 0px 24px;
}

.products-table {
  width: 100%;
  margin-bottom: 32px;

  /* padding-left: 24px; */
}

.product-name-cell {
  text-transform: capitalize;
  font-weight: 600;
}

.products-table-cell {
  padding: 12px;
}
.products-column {
  width: 30%;
}

.status-cell-staged {
  text-transform: capitalize;
  color: #e15608;
}

.status-cell-published {
  text-transform: capitalize;
  color: #219653;
}

.collections-table-search-bar {
  background: url('../images/svg-icons/search-icon.svg') no-repeat;

  background-position: 96% 50%;
  border-radius: 8px;
  padding: 8px 16px;
  background-color: white;
}

.search-form {
  margin-top: 16px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.modal-content button {
  margin: 10px;
  border: 1px solid #007bff;
  padding: 5px 15px;
  background-color: white;
  color: #007bff;
  border-radius: 4px;
  cursor: pointer;
}

.modal-content button:hover {
  background-color: #007bff;
  color: white;
}

.modal-content .input-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.modal-content input[type='text'],
.modal-content input[type='file'] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.modal-content .button-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.modal-content .submit-button {
  color: white;
  background-color: #3267e3;
  border: 1px solid #3267e3;
  border-radius: 6px;
  padding: 6px 16px 6px 16px;
}

.product-thumbnail-container:hover .product-thumbnail-image {
  filter: grayscale(100%);
}

.product-thumbnail-container {
  position: relative;
  cursor: pointer;
}

.product-thumbnail-container:hover .upload-icon-container {
  display: flex;
}

.upload-icon-container {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.product-thumbnail-image {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 4px;
  transition: filter 0.3s ease;
}

.add-image-placeholder {
  width: 48px;
  height: 48px;
  border-radius: 4px;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
}
