.title {
  position: absolute;
  /* width: 190px;
    height: 72px; */
  left: 36px;
  bottom: 64px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 72px;
  /* identical to box height */

  letter-spacing: 0.2px;

  color: #ffffff;
}

.subtitle {
  position: absolute;
  height: 36px;
  left: 36px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  letter-spacing: 0.2px;

  color: #ffffff;
  bottom: 36px;
}

.shows-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 24px;
  padding-bottom: 24px;
}

.divShow {
  background-color: blueviolet;
  background-position: center;
  width: 100%;
  max-width: 1370px;
  min-width: 600px;
  height: 400px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 25px;
  padding: 36px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.5);
}

.nft-req-logo {
  position: relative;
  left: 736px;
  top: 396px;
}

.nft-title {
  position: absolute;
  right: 36px;
  bottom: 96px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  display: flex;
  letter-spacing: 0.2px;
  gap: 0.3em;
  color: #ffbd1b;
}

.nft-detected {
  color: #1bff69;
}

.secondary-banner {
  display: flex;
  width: 100%;
  min-width: 600px;
  max-width: 1370px;
  position: relative;
}

.secondary-banner img {
  display: flex;
  width: 100%;
}

.ff-button {
  background: #5239ea;
  height: 50px;
  min-width: 150px;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 11px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  padding: 8px;
}

.ff-button-orange {
  background: #ff7a00;
}

.ff-button:disabled {
  background-color: #434343;
}

a.ff-button {
  line-height: 50px;
}

.view-bot,
.view-deluxe,
.view-mint {
  position: absolute;
  bottom: 15%;
}

.enter-world {
  position: absolute;
  right: 36px;
  bottom: 36px;
}

.view-bot {
  left: 463px;
}

.view-deluxe {
  background: #e37123;
  left: 263px;
}

.view-mint {
  left: 5%;
}
