input.event-input {
  color: black;
}

.info-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  border: 2px solid white;
  border-radius: 20px;
  padding: 24px;
}
.input-container {
  display: flex;
  gap: 12px;
  align-items: center;
}

.input-container label {
  min-width: 120px;
  text-align: right;
}

.input-container input[type='text'] {
  display: flex;
  flex-grow: 1;
}

.actions-container {
  display: flex;
  justify-content: center;
  gap: 12px;
}
