.App {
  background-color: #141414;
  min-height: 100vh;
  overflow: hidden;
  color: rgb(255, 255, 255);
}

.login-buttons {
  float: right;
  text-align: center;
}

iframe {
  border-width: 0;
}

.fullscreen {
  width: 100%;
  height: 100%;
}

.ff-center {
  align-items: center;
}
