.wallet-option-button {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 15px 30px;
  background-color: white;
  color: #6200ee;
  border: 1px solid #6200ee;
  border-radius: 15px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover state */
}

.wallet-option-button:hover {
  background-color: #6200ee; /* Primary color for hover state */
  color: white; /* White text for hover state */
}

.wallet-option-icon {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}
