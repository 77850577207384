.App-header {
  background-color: #090909;
  min-height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: white;
  align-items: center;
  gap: 3em;
  padding-left: 5%;
  padding-right: 5%;
  margin: 0%;
}

.ff-logo {
  height: 52.95px;
  width: 230px;
  float: left;
}

.mobile-blocker {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100001;
  background: #000;
  color: #fff;
  text-align: center;
  display: none;
}

@media all and (max-width: 767px) {
  .mobile-blocker {
    display: block;
  }
}
.CartModal__Content {
  /* position: absolute; */
  width: 1128px;
  height: 592px;
  font-family: 'Poppins';
  color: white;
  background: #1f1f1f;
}
.cart-item {
  display: flex;
  gap: 10px;
}

.PrimaryCurrencyModal__Content {
  position: relative;
  width: 1128px;
  /* height: 592px; */
  font-family: 'Poppins';
  /* color: white; */
}

.header-right-content {
  display: flex;
  gap: 13px;
  justify-content: center;
  align-items: center;
}
.cart {
  display: flex;
  gap: 2px;
  justify-content: center;
  align-items: center;
}
.cart-logo {
  margin-right: 3px;
}
.cart-quantity {
  margin-top: -17px;
  font-size: 12px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #f77951;
  color: white;
}
.quantity {
  text-align: center;
}
