.admin-content {
  padding: 24px;
  display: flex;
  gap: 24px;
  flex-direction: column;
}

.panel {
  border: 5px solid white;
  padding: 24px;
  border-radius: 12px;
}
.admin-console {
  display: flex;
  width: 100%;
  height: 100%;

  color: white;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}
.admin-console__tab-list {
  background-color: black;
  padding: 0%;
  margin: 0%;
  width: 236px;
  padding-top: 48px;
  position: relative;
  min-width: 236px;
}

.admin-console__tab-panel {
  background-color: #1f1f1f;
}

.admin-console__tab-panel--selected {
  padding: 48px;
  flex-grow: 1;
}

.admin-console__tab {
  list-style: none;
  height: 48px;
  line-height: 48px;
  white-space: nowrap;
  padding-left: 12px;
  cursor: pointer;
}

.admin-console__tab--selected {
  background-color: #1f1f1f;
}
