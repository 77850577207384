.login-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 42px;

  width: 139px;
  height: 50px;
  right: 9px;
  top: calc(50% - 52px / 2);

  background: #4452fe;
  box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  border-color: transparent;

  flex: none;
  order: 0;
  flex-grow: 0;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 25px;

  letter-spacing: 0.2px;

  color: #ffffff;
  float: right;
}

.login-btn:hover {
  background-color: #6a75ff;
}

.ff-button-login {
  background: #5239ea;
  height: 50px;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 11px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  font-style: normal;
  text-decoration: none;
}
