.cart-container {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 0px 20px;
}
.left-content {
  width: 50%;
  padding: 11px 30px;
}
.right-content {
  background: #303030;
  border-radius: 0px 20px 20px 0px;
  width: 50%;
  padding: 51px 0px;
}
.cart-heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 72px;
  letter-spacing: 0.2px;
  color: #ffffff;
}
.cart-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 0px 20px;
}
.item-details {
  display: flex;
  flex-direction: column;
  height: 0px;
  margin-top: 4px;
}
.cart-item-name {
  margin-top: -10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 35px;
  color: #ffffff;
  flex: none;
}
.item-remove-button {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 2px;
  text-align: left;
  letter-spacing: 0.015em;
  color: #f77951;
  cursor: pointer;
}
.cart-item-price {
  margin-bottom: 13px;
  margin-bottom: 13px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.totaling {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 130px;
  padding: 0px 20px;
}
.divider {
  width: 47%;
  position: relative;
  left: 248px;
}
.payment {
  height: 100%;
  right: 0px;
  display: flex;
  flex-shrink: 0;
  flex-basis: 100px;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.payment_tabs {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  list-style-type: none;
  border: 1px solid #cfcfcf;
  padding-top: -1px;
  margin-left: 1;
  padding: 0px;
  border-radius: 11px;
}
.payment_tab {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  color: #ffffff;
  height: 45px;
  padding: 15px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  font-feature-settings: 'tnum' on, 'lnum' on;
}
.payment_tab--selected {
  border: 1px solid;
  background: #f77951;
  border-radius: 6px;
}
.payment_tab-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.wallet-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.wallet-icon {
  background: #ffffff;
  border: 1px solid #cfcfcf;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
}
.wallet-highlited {
  border: 2px solid #f77951;
}
.payment-mode {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  margin-left: -7px;
}

.item-image {
  width: 48px;
  height: 48px;
}
.nft-bind {
  display: flex;
  flex-direction: column;
}
.nft-inputs {
  background: #3c4257;
  border-radius: 4px;
  margin-bottom: 12px;
  width: 421px;
  height: 260px;
}
.nft-input {
  padding: 12px;
  width: 410px;
  height: 48px;
  background: #f5f5f5;
  border-radius: 4px;
  border: none;
  margin-top: 4px;
  margin-left: 4px;
}
.nft_details {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 4px, 4px;
  padding: 9px 14px;
  height: 186px;
  overflow-x: hidden;
  margin-top: 5px;
  gap: 4px;
  width: 412px;
}
/* Customize the scrollbar for a specific div */
.nft_details::-webkit-scrollbar {
  width: 5px;
}
.nft_details::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}
.nft_details::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.nft {
  display: flex;
  flex-direction: column;
  margin-left: -8px;
  border: 1px solid#787878;
  border-radius: 4px;
}
.checkout-items {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  max-height: 400px;
}
.empty-cart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 35px;
}
.empty-cart-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #a1a1a1;
  margin: auto;
}
.empty-cart a {
  text-decoration: none;
}

.purchase-msg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.thanks-msg {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 72px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #ffffff;
  width: 39%;
}
.confirmation-msg {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 85px;
  color: #ffffff;
}
.back-to-store-btn {
  background: #f77951;
  width: 252px;
  height: 56px;
  background: #f77951;
  border-radius: 28px;
}
.back-to-store-btn-txt {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
}
.back-to-store-btn-txt:hover {
  color: #ffffff;
}
.cart-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 0px 20px;
  align-items: center;
  justify-content: center;
}
.select-nft-to-bind {
  width: 421px;
  height: 48px;
  background: #3c4257;
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.12), 0px 2px 5px rgba(0, 0, 0, 0.12),
    0px 1px 1px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}
.select-nft-to-bind-txt {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;

  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #ffffff;
}
.nft-binding-details {
  width: 421px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin: 0px;
  color: #a1a1a1;
}
.learn-about-nft-binding {
  text-decoration: none;
}
.without-nft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 62px;
}
.no-nft-detected {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #ffffff;
}
.nft-doubt {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  text-decoration: none;
  color: #a5beff;
}
.pay {
  width: 421px;
  height: 48px;
  background: #3c4257;
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.12), 0px 2px 5px rgba(0, 0, 0, 0.12),
    0px 1px 1px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  margin-top: 20px;
}
.pay-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #ffffff;
}
.nft-detail {
  display: flex;
  flex-direction: column;
  line-height: 25px;
  padding: 4px 7px;
}
.nft-name {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #ffffff;
}
.nft-address {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #ffffff;
}
.nft-detail:active {
  background: #f77951;
  border-radius: 4px;
}
.selected-nft-img {
  width: 48px;
  height: 48px;
  margin-top: 2px;
  border-radius: 4px;
  margin-left: 5px;
}
.selected-nfts-details {
  flex-flow: row nowrap;
  align-items: center;
  background: #f77951;
  border-radius: 4px;
  width: 421px;
  height: 60px;
}
.selected-parent-nft {
  display: flex;
  width: 421px;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}
.selected-child-nft {
  display: flex;
  flex-direction: column;
  text-align: start;
  justify-content: center;
  margin-right: 121px;
}
.selected-nft-address {
  margin-top: -10px;
}
.cross-img {
  margin-right: 5px;
}
.nft-binding-doubts {
  padding-top: 7px;
}
