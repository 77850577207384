.tablink {
  text-decoration: unset;

  /* background-color: #1B1B1C; */
  position: relative;
  color: white;

  border: none;
  outline: none;
  cursor: pointer;
  font-size: 18px;
  line-height: 27px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  letter-spacing: 0.2px;
  /* width: 196px; */
  /* border: 5px #581EFC; */

  /* background: #1B1B1B;
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 11px;
   */
  height: 48px;
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.tablink-active h4 {
  /* background: -webkit-linear-gradient(#EC7AFF, #725BFF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
}

/* .App-header .tablink:nth-child(2)::before {
    content: '';
    height: 25px;
    width: 25px;
    vertical-align: middle;
    background: url("./images/Polygon_11.png");
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 20px;
    margin-left: 20px;
}
  
.App-header .tablink-active:nth-child(2)::before {
    background: url("./images/Polygon_10.png");
    background-size: contain;
    background-repeat: no-repeat;
} */

/* .App-header .tablink:nth-child(3)::before {
    content: '';
    height: 45px;
    width: 45px;
    vertical-align: middle;
    background: url("./images/green_robot.png");
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 15px;
    margin-left: 5px;
    align-self: flex-end;
} */

.enter-world-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.stream-box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.navigation {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  list-style-type: none;
}

.navigation_tab {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* height: 100%; */
  /* width: 136px; */
  justify-content: center;
  border-bottom: 2px solid #656559;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.navigation_tabs {
  /* display: flex; */
  display: none;
  list-style-type: none;
  margin: 0%;
  padding: 0%;
  height: 100%;
  align-items: center;
  gap: 12px;
}

.navigation_tab-panel--selected {
  width: 100%;
  height: 100%;
  list-style-type: none;
}

.navigation_tab-list {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-around;
  /* height: 100%; */
  margin: 0px;
}

.ff-links {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: white;
  text-decoration: none;
  border-bottom-width: 0px;
  display: none;
}

.ff-links:hover {
  color: #a864ff;
}

.navigation_tab--selected {
  /* font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    background: linear-gradient(to right, #EC7AFF, #725BFF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    list-style-type: none; */
  border-bottom: 2px solid #725bff;
}

.tablink-active {
  /* background-image: linear-gradient(#1B1B1B, #1B1B1B), linear-gradient(#EC7AFF,#725BFF);
    background-origin: border-box;
    background-clip: content-box, border-box; */
  /* border: double 2px transparent; */
  /* border: double 2px #581EFC; */
  color: #a864ff;
}

.enter-world-button {
  background-color: green;
  border-bottom: unset;
  color: white;
  border-radius: 7px;
  width: 136px;
}

.enter-world-button:hover {
  color: white;
}
