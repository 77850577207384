#unity-progress-bar-full {
  transition: width 0.5s ease-in-out;
  background: violet;
  box-shadow: 0 0 10px rgba(238, 130, 238, 0.5);
  border-radius: 10px;
  height: 10px;
  animation: pulse 1.5s ease-in-out infinite;
}

#unity-container {
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
}
