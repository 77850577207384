.currency-bundle-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.currency-bundle {
  text-align: center;
  margin: 0px;
  transition: box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative; /* Added this line */
}

.currency-bundle:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

.currency-bundle.selected {
  background-color: #8071f4; /* Optional: only if you want to change bg on select */
}

.currency-bundle-btn {
  border: none;
  background: none;
  position: relative; /* Makes it the anchor point for absolutely positioned children */
  display: block;
  cursor: pointer;
  width: 100%; /* Make the button fill its parent */
  padding: 10px 0px; /* Adjust padding to your liking */
}

.currency-bundle-img {
  width: 100px;
  height: auto;
}

.currency-amount-overlay {
  position: absolute;
  bottom: 35px; /* You can adjust this value to position the overlay */
  left: 50%;
  transform: translateX(-50%);
  color: white;
  /* background-color: rgba(0, 0, 0, 0.5); */
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.amount-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.bundle-price {
  font-size: 1.2rem;
  color: #333;
  margin-top: 5px;
}

.checkout-button-container {
  text-align: center;
  margin-top: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .currency-bundle-container {
    flex-direction: column;
  }

  .currency-bundle-btn {
    width: auto;
  }
}
