.connect-wallet-title {
  font-family: 'Poppins';
  font-style: bold;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.2px;

  color: #000000;
}

.connect-wallet-subtitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.2px;

  color: #818a92;
}

.connect-wallet-footnote {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.2px;

  color: #818a92;
}

.wallet-name {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  /* margin: 0; */
  padding: 0;

  border: 1px solid #e5e8eb;
  box-sizing: border-box;
  border-radius: 12px;
}

.wallet-name li {
  height: 64px;
}

.wallet-name li:not(:last-child) {
  border-bottom: 1px solid #e5e8eb;
}

.wallet-add-button {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  border: 0;
  background-color: transparent;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: 0.2px;

  color: #000000;

  text-align: left;
  padding: 12px;
}

.connect-wallet-footnote a {
  text-decoration: none;
}
