.primary-currency-modal {
  position: absolute;
  right: 0; /* Aligns the modal to the right edge of the parent */
  top: 0;
  background: hsl(0, 0%, 100%);
  /* border-radius: 16px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 100%; /* Adjust width as needed */
  margin: 0 auto; /* Center the modal */
}

.primary-currency-modal-header {
  font-size: 24px;
  font-weight: bolder;
  color: #333;
  text-align: left;
  margin-bottom: 20px;
}

.primary-currency-modal-header-02 {
  font-size: 18px; /* Adjust the size as necessary to match your design */
  color: #333; /* This is a dark color, adjust if your design uses a different color */
  text-align: center; /* Centers the text */
  margin-top: 40px; /* Adds space above the header */
  margin-bottom: 20px; /* Adds space below the header before the payment methods */
}

.select-wallet-header {
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.select-wallet-header::before,
.select-wallet-header::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 30%; /* Adjust the width as necessary */
  height: 1px;
  background-color: #ccc; /* This color should match the line color */
}

.select-wallet-header::before {
  left: 0;
  margin-left: 5%; /* Adjust the margin to control the starting point of the line */
}

.select-wallet-header::after {
  right: 0;
  margin-right: 5%; /* Adjust the margin to control the end point of the line */
}

.primary-currency-modal-header-03 {
  display: inline-block;
  position: relative;
  background: #fff; /* Match the modal background color */
  padding: 0 10px; /* Padding to ensure the lines don't touch the text */
}

.currency-tile-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.currency-tile {
  background: #5c6ac4; /* Adjust the background color as needed */
  color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  width: 80px; /* Adjust tile width as needed */
}

.currency-tile img {
  width: 100%; /* Images take full width of the tile */
  margin-bottom: 8px;
}

.currency-tile .price {
  font-weight: bold;
}

.payment-method-container {
  border-top: 1px solid #eee;
  padding-top: 20px;
  margin-top: 20px;
}

.payment-button {
  background: #f0f1f5;
  border: none;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box; /* Makes sure padding does not affect overall width */
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-button img {
  margin-right: 10px;
}

.wallet-selector-container {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
}

.wallet-button {
  background: #ffffff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box; /* Makes sure padding does not affect overall width */
  display: flex;
  align-items: center;
}

.wallet-button img {
  margin-right: 10px;
}

/* Active states and other interactivity styles */
.currency-tile.active,
.payment-button.active,
.wallet-button.active {
  border: 2px solid #0000ff; /* Highlight the active selection */
}

/* Responsiveness and other states can be added here */
@media (max-width: 768px) {
  .primary-currency-modal {
    width: auto;
    padding: 10px;
  }

  .currency-tile-container {
    flex-direction: column;
  }
}
