.checkout-with-credit-card-btn {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 15px 30px; /* More padding for a larger button */
  background-color: white; /* Background is white */
  color: #6200ee; /* Text color matches your primary color */
  border: 1px solid #6200ee; /* Border color matches your primary color */
  border-radius: 15px; /* More rounded borders for the button */
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  cursor: pointer;
  font-size: 16px; /* Larger font size for visibility */
  text-transform: uppercase; /* Uppercase text for stylistic choice */
  font-weight: bold; /* Bold font weight for prominence */
  margin-top: 10px; /* Adjust as necessary for spacing */
  margin-bottom: 10px; /* Adjust as necessary for spacing */
  width: 100%;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover state */
}

.checkout-with-credit-card-btn:hover {
  background-color: #6200ee; /* Primary color for hover state */
  color: white; /* White text for hover state */
}

.checkout-with-credit-card-btn img {
  margin-right: 10px; /* Space between icon and text */
  width: 24px; /* Icon width */
  height: 24px; /* Icon height, adjust as necessary */
}
