.video-container {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  /* margin: 15px; */
}

#amazon-ivs-videojs {
  height: 100%;
  padding: 0;
}
