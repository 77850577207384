.AddWalletModal__Content {
  /* position: absolute; */
  width: 360px;
  color: #000000;
  background: #ffffff;

  padding: 36px 24px;
}

.AccountSettingsModal__Content {
  /* position: absolute; */
  width: 1128px;
  height: 592px;
  font-family: 'Poppins';
  color: white;
  background: #1f1f1f;
}

.ff-modal-content {
  box-shadow: 30px 30px 60px rgba(0, 0, 0, 0.25);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 96px;
}

.primarycurrency-modal-content {
  box-shadow: 30px 30px 60px rgba(0, 0, 0, 0.25);
  /* border-radius: 20px; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 96px;
}

.ff-overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.8);
}

.ff-primarycurrency-overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.4);
}

#dropdown-basic {
  background-color: unset;
  border-color: unset;
  border-width: 0px;
  box-shadow: unset;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.dropdown-menu {
  background-color: black;
  z-index: 100000;
}

.dropdown-menu.show {
  display: flex;
  flex-direction: column;
  padding: 8px;
  width: max-content;
}

.dropdown-item {
  display: flex;
  gap: 8px;
  color: white;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  padding: 8px;
}
